<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="600"
    v-if="serverData"
  >
    <div class="bg-white poppins h-100">
      <div class="modal-header align-center">
        <h3 class="mb-0 font-weight-bolder">Cancel SKU</h3>
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal(null)"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
      </div>
      <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7">
        <!--begin::Body-->
        <form id="createForm" class="pa-3 pb-0 py-0 my-0">
          <div class="h-75px col-12 py-0 my-0">
            <v-text-field
              v-model="formData.qty"
              label="Quantity"
              outlined
              dense
              :max="serverData.qty"
              :min="1"
              hide-details
              type="number"
            />
          </div>
        </form>

        <div
          class="modal-footer border-0 py-2 d-flex flex-grow-1 align-end justify-center"
        >
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="submitForm"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import ApiService from "@/core/services/api.service";
import fieldValueValidation from "@/own/mixins/fieldValueValidation";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import SwalService from "@/core/services/swal.service";

export default {
  mixins: [fieldValueValidation],
  name: "CancelDetails",
  props: {
    getDataFromApi: {
      required: true,
      type: Function,
    },
    // extraData: {
    //   default: () => {},
    //   type: Object,
    // },
  },
  data: () => ({
    dialog: false,
    serverData: null,
    extraData: {},
    formData: {
      qty: 1,
    },
  }),
  methods: {
    toggleModal(currentData, extraData) {
      if (this.dialog) {
        this.resetForm();
      }
      this.serverData = currentData;
      if (extraData) {
        this.extraData = extraData;
      }

      this.dialog = !this.dialog;
    },

    submitForm() {
      if (this.formData.qty > this.serverData.qty) {
        SwalService.warningMessage({
          html: `Quantity can not be more then ${this.serverData.qty} !`,
        });
        return;
      }
      this.$store.commit(SET_PAGE_LOADING, true);
      let data = { ...this.formData, ...this.extraData };
      data["id"] = this.serverData.id;
      data["type"] = this.serverData.type;
      ApiService.post("/fulfillment/orders/cancel-order-sku", data)
        .then(() => {
          SwalService.successMessage({
            title: SwalService.titles.success,
            html: SwalService.messages.successifully_completed,
          });
          this.toggleModal(null);
          this.getDataFromApi();
        })
        .finally(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
      // }
    },
    resetForm() {
      this.formData = {
        qty: 1,
      };
    },
  },
};
</script>
