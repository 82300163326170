<template>
  <v-card class="mt-4">
    <v-toolbar color="primary h5 text-white">
      <div class="w-100 d-flex align-items-center justify-space-between">
        <div>
          {{ item.title }}
        </div>
        <v-spacer></v-spacer>
        <div class="pe-4" v-if="item?.header_actions?.create_order_sku">
          <v-btn dense @click="createSKU">Create</v-btn>
        </div>
        <div class="bg-white px-3 rounded">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            clearable
            single-line
            hide-details
            class="min-w-250px"
            @keyup="debounceSearch"
            @click:clear="debounceSearch"
          />
        </div>
      </div>
    </v-toolbar>
    <v-card-text class="pa-4">
      <v-tabs v-model="tabs" mobile-breakpoint="300">
        <v-tab v-for="(tab, i) in item.data.tabs" :key="i">
          <h3 class="font-weight-medium ma-0 font-size-lg poppins">
            {{ tab.label }}
          </h3>
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tabs">
        <v-tab-item v-for="(tab, i) in item.data.tabs" :key="i">
          <v-data-table
            disable-sort
            :headers="tab.header.filter((head) => head.visibility == true)"
            :items="getItems"
            :options.sync="options"
            :server-items-length="total"
            :loading="loading"
            class="elevation-0"
            :footer-props="{ 'items-per-page-options': item_per_page_list }"
          >
            <template
              v-for="head in tab.header"
              v-slot:[`item.`+head.value]="{ item, header, value }"
            >
              <template v-if="head.value == 'last_status'">
                <StatusColumn :key="head.value" :value="value" />
              </template>
              <template v-else>
                <span :key="head.value" class="second-text"> {{ value }}</span>
              </template>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-menu
                left
                transition="scale-transition"
                offset-y
                bottom
                rounded
                v-if="
                  Object.entries(item?.actions).filter((action) => action[1])
                    .length > 0
                "
              >
                <template v-slot:activator="{ on, attrs }">
                  <a
                    v-bind="attrs"
                    v-on="on"
                    class="btn btn-light-light btn-hover-info show poppins min-w-100px min-w-md-110px"
                  >
                    Actions
                    <span class="svg-icon svg-icon-5 m-0">
                      <v-icon small>mdi-chevron-down</v-icon>
                    </span>
                  </a>
                </template>
                <v-card class="poppins py-1" elevation="1">
                  <div v-if="item?.actions?.edit_order_sku">
                    <v-btn
                      class="w-100 text-left text-dark"
                      @click="() => editSKU(item)"
                      text
                      >Edit</v-btn
                    >
                  </div>
                  <div v-if="item?.actions?.edit_asn_sku">
                    <v-btn
                      class="w-100 text-left text-dark"
                      @click="() => editAsnSKU(item)"
                      text
                      >Edit</v-btn
                    >
                  </div>
                  <div v-if="item?.actions?.cancel_receiving">
                    <v-btn
                      class="w-100 text-left text-dark text-dark"
                      @click="cancelReceivingAsnSku(item)"
                      text
                      >Cancel Receiving</v-btn
                    >
                  </div>
                  <div v-if="item?.actions?.cancel_order_sku">
                    <v-btn
                      class="w-100 text-left text-dark"
                      @click="() => cancelOrderSKU(item)"
                      text
                      >Cancel Order SKU</v-btn
                    >
                  </div>
                  <div v-if="item?.actions?.cancel_order_pick">
                    <v-btn
                      class="w-100 text-left text-dark"
                      @click="() => cancelOrderAllocationSKU(item)"
                      text
                      >Cancel</v-btn
                    >
                  </div>
                  <div v-if="item?.actions?.cancel_non_allocated_order_sku">
                    <v-btn
                      class="w-100 text-left text-dark"
                      @click="() => cancelOrderNonAllocatedSKU(item)"
                      text
                      >Cancel</v-btn
                    >
                  </div>
                  <div v-if="item?.actions?.instant_allocation_order_sku">
                    <v-btn
                      class="w-100 text-left text-dark"
                      @click="() => instantAllocationOrderSKu(item)"
                      text
                      >Instant allocation</v-btn
                    >
                  </div>
                </v-card>
              </v-menu>
            </template>
          </v-data-table>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
    <CreateSKUDetails
      ref="createSKUDetails"
      :orderId="item.id"
      :getDataFromApi="updateData"
    />
    <EditSKUDetails ref="editSKUDetails" :getDataFromApi="updateData" />
    <CancelDetails ref="cancelSku" :getDataFromApi="updateData" />
    <EditAsnSKUDetails ref="editAsnSKUDetails" :getDataFromApi="updateData" />
    <v-dialog v-model="dialog3" persistent width="400"
      ><v-card class="text-center"
        ><v-card-title class="py-0 pt-4">Are you sure?</v-card-title>
        <v-divider class="py-0"></v-divider>
        <v-card-text class="h6">You won't be able to revert this!</v-card-text
        ><v-card-actions class="justify-content-center">
          <v-btn
            dark
            color="blue"
            @click="cancelReceivingApiRequest(itemforaction)"
            >Yes</v-btn
          ><v-btn
            dark
            color="red"
            @click="
              () => {
                dialog3 = false;
              }
            "
            >No</v-btn
          >
        </v-card-actions></v-card
      ></v-dialog
    >
  </v-card>
</template>

<script>
import ApiService from "@/core/services/api.service";
import StatusColumn from "@/own/components/datatable/StatusColumn.vue";
import EditSKUDetails from "@/own/components/fulfillment/orders/editSKUDetails/EditSKUDetails.vue";
import CancelDetails from "@/own/components/fulfillment/orders/editSKUDetails/CancelDetails.vue";
import CreateSKUDetails from "@/own/components/fulfillment/orders/editSKUDetails/CreateSKUDetails.vue";
import EditAsnSKUDetails from "@/own/components/fulfillment/asn/dyntabtableSKUDetails/EditAsnSKUDetails.vue";
import SwalService from "@/core/services/swal.service";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
export default {
  name: "PopUpDynamicTabDataTableWithSearch",
  components: {
    StatusColumn,
    EditSKUDetails,
    CreateSKUDetails,
    EditAsnSKUDetails,
    CancelDetails,
  },
  props: ["item", "updateDetailsData", "order_id"],
  data: () => ({
    dialog3: false,
    itemforaction: null,
    tabs: null,
    debounce: null,
    search: "",
    total: 0,
    options: {},
    loading: true,
    items: [],
    item_per_page_list: [20, 30, 40, 50],
  }),
  mounted() {
    this.getDataFromApi();
  },
  methods: {
    updateData() {
      this.updateDetailsData(this.order_id, true, false);
    },
    debounceSearch() {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.getDataFromApi();
      }, 600);
    },
    getDataFromApi() {
      this.loading = true;
      const { page, itemsPerPage } = this.options;

      let data = {
        id: this.item.id,
        page: page,
        per_page: itemsPerPage,
      };

      if (this.search) {
        data.q = this.search;
      }

      ApiService.post(this.item.url, data)
        .then((response) => {
          this.total = response.data.data.total;
          this.items = response.data.data.data;

          this.dialog = false;
        })
        .catch(() => {
          this.dialog = false;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    createSKU() {
      this.$refs.createSKUDetails.toggleModal();
    },
    editSKU(item) {
      // console.log(item);
      this.$refs.editSKUDetails.toggleModal(item.order_sku_id);
    },
    editAsnSKU(item) {
      this.$refs.editAsnSKUDetails.toggleModal(item.asn_sku_id);
    },
    cancelOrderSKU(item) {
      this.$refs.cancelSku.toggleModal({
        item: item,
        type: "item",
        id: item["order_sku_id"],
        qty: item.quantity,
      });
    },
    cancelOrderAllocationSKU(item) {
      this.$refs.cancelSku.toggleModal({
        item: item,
        type: "pick",
        id: item["picking_task_id"],
        qty: item.allocated_qty,
      });
    },
    cancelOrderNonAllocatedSKU(item) {
      this.$refs.cancelSku.toggleModal({
        item: item,
        type: "non-allocated-item",
        id: item["order_sku_id"],
        qty: item.quantity,
      });
    },
    cancelReceivingApiRequest(item) {
      this.$store.commit(SET_PAGE_LOADING, true);
      ApiService.post("/fulfillment/asn/cancel-asn-sku", {
        id: item.asn_sku_id,
      })
        .then(() => {
          SwalService.successMessage({
            html: SwalService.messages.updated(),
          });
          this.dialog3 = false;
          this.$store.commit(SET_PAGE_LOADING, false);
          this.updateDetailsData(this.order_id, true, false);
        })
        .catch(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
    cancelReceivingAsnSku(item) {
      this.dialog3 = true;
      this.itemforaction = item;
      // SwalService.warningConditionMessage(
      //   {
      //     title: "Are you sure?",
      //     html: "You won't be able to revert this!",
      //     icon: "warning",
      //     showCancelButton: true,
      //     confirmButtonColor: "#3085d6",
      //     cancelButtonColor: "#d33",
      //     confirmButtonText: "Yes",
      //     // confirmButtonText: "Yes, cancel it!",
      //   },
      //   () => {
      //     ApiService.post("/fulfillment/asn/cancel-asn-sku", {
      //       id: item.asn_sku_id,
      //     }).then(() => {
      //       SwalService.successMessage({
      //         html: SwalService.messages.updated(),
      //       });
      //       this.dialog3 = false;
      //     });
      //   },
      //   () => {
      //     this.dialog3 = false;
      //   }
      // );
      // Swal.fire({
      //   // heightAuto: false,
      //   // backdrop:false,
      //   // scrollbarPadding:false,
      //   // returnFocus: false,
      //   toast: false,
      //   title: "Are you sure?",
      //   text: "You won't be able to revert this!",
      //   icon: "warning",
      //   showCancelButton: true,
      //   confirmButtonColor: "#3085d6",
      //   cancelButtonColor: "#d33",
      //   confirmButtonText: "Yes",
      //   // didClose: () => window.scrollTo(0,10000),
      //   // onAfterClose: () => window.scrollTo(0,10000),
      // }).then((result) => {
      //   if (result.isConfirmed) {
      //     ApiService.post("/fulfillment/asn/cancel-asn-sku", {
      //       id: item.asn_sku_id,
      //     }).then(() => {
      //       SwalService.successMessage({
      //         html: SwalService.messages.updated(),
      //       });
      //     });
      //   }
      // });
    },
    instantAllocationOrderSKu(item) {
      SwalService.warningConditionMessage(
        { html: SwalService.titles.are_you_sure },
        () => {
          ApiService.post("/fulfillment/orders/instant-allocate-order-sku", {
            id: item.order_sku_id,
          }).then(() => {
            SwalService.successMessage({
              html: SwalService.messages.updated(),
            });
          });
        },
        () => {}
      );
    },
  },
  computed: {
    getItems() {
      return this.items;
    },
  },
  watch: {
    options: {
      handler() {
        this.getDataFromApi();
      },
      deep: true,
    },
  },
};
</script>
