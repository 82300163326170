<template>
  <v-dialog
    v-model="dialog"
    persistent
    scrollable
    max-width="1400px"
    v-if="isDataLoadedFromServer"
    style="background-color: white !important; height: 80vh !important"
  >
    <div class="bg-white poppins modal-root">
      <div class="modal-header align-center">
        <h3 class="mb-0 font-weight-bolder">Create Order</h3>
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
      </div>

      <div class="d-flex flex-column mx-5 modal-body">
        <!--begin::Body-->
        <!-- <v-tabs
          v-model="tab"
          background-color="transparent"
          color="basil"
          grow
          show-arrows
        >
          <v-tab v-for="item in categories" :key="item">
            {{ item }}
          </v-tab>
        </v-tabs> -->
        <v-stepper
          v-model="tab"
          non-linear
          outlined
          rounded="4"
          flat
          v-if="isStepperReady"
          @change="updateValidationStateOfSteps"
        >
          <v-stepper-header class="step-head-main">
            <v-stepper-step
              v-for="(item, i) in categories"
              :key="i"
              class="step-head"
              complete
              editable
              complete-icon="mdi-check-circle"
              edit-icon="mdi-check"
              error-icon="mdi-alert-circle"
              :step="i"
              :rules="[
                () => {
                  return item.valid;
                },
              ]"
            >
              {{ item.name }}
              <small v-if="!item.valid">Invalid</small>
            </v-stepper-step>
          </v-stepper-header>
          <v-stepper-items class="">
            <!-- categories: ["Finance", "Cosignee", "Sku", "Address", "Order Details"], -->
            <v-stepper-content step="0" key="Order Details" class="pt-2">
              <div class="row">
                <div class="col-12 col-sm-6 col-md-4">
                  <v-text-field
                    class
                    v-model="order_details.client_reference"
                    label="Client reference"
                    clearable
                    outlined
                    dense
                    :error-messages="client_referenceErrors"
                    @input="$v.order_details.client_reference.$touch()"
                    @blur="$v.order_details.client_reference.$touch()"
                  ></v-text-field>
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                  <v-autocomplete
                    v-model="order_details.store"
                    label="Store"
                    item-text="text"
                    item-value="index"
                    :items="serverData.stores"
                    clearable
                    outlined
                    dense
                    :error-messages="storeErrors"
                    @input="$v.order_details.store.$touch()"
                    @blur="$v.order_details.store.$touch()"
                  ></v-autocomplete>
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                  <v-autocomplete
                    v-model="order_details.warehouse"
                    label="Warehouse"
                    item-text="text"
                    item-value="index"
                    :items="serverData.warehouses"
                    clearable
                    outlined
                    dense
                    :error-messages="warehouseErrors"
                    @input="$v.order_details.warehouse.$touch()"
                    @blur="$v.order_details.warehouse.$touch()"
                  ></v-autocomplete>
                </div>
                <div class="col-12">
                  <v-text-field
                    class
                    v-model="order_details.note"
                    label="Comment"
                    clearable
                    outlined
                    dense
                  ></v-text-field>
                </div>
              </div>
            </v-stepper-content>

            <v-stepper-content step="1" class="pt-2">
              <v-card color="basil" flat>
                <v-col cols="12">
                  <sku-datatable
                    :clientId="userId"
                    :warehouse="order_details.warehouse"
                    :store="order_details.store"
                  ></sku-datatable>
                </v-col>
              </v-card>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>

        <div class="d-flex align-center modal-action flex-wrap pt-4">
          <div class="justify-content-between align-center d-flex col-12">
            <v-btn
              v-if="backState"
              class="order-1 order-sm-1"
              @click="nextStep('prev')"
              color="primary"
              dark
              ><v-icon>mdi-arrow-left</v-icon></v-btn
            >
            <div v-else class="order-1 order-sm-1"></div>
            <div
              class="order-2 order-sm-2 d-flex flex-wrap justify-content-center align-center"
              :class="{ invisible: nextState }"
            >
              <button
                type="submit"
                class="btn btn-danger px-5 py-3 mx-2 order-2 mt-1 mt-sm-0"
                @click="toggleModal"
              >
                Close
              </button>
              <button
                type="submit"
                class="btn btn-info px-5 py-3 mx-2 order-1 mt-1 mt-sm-0"
                @click="submitEditForm"
              >
                Create Order
              </button>
            </div>
            <v-btn
              v-if="nextState"
              class="order-3 order-sm-3"
              @click="nextStep('next')"
              color="primary"
              dark
              ><v-icon>mdi-arrow-right</v-icon></v-btn
            >
            <div v-else class="order-3 order-sm-3"></div>
          </div>
        </div>
      </div>
    </div>
    <v-dialog
      transition="dialog-bottom-transition"
      max-width="600"
      v-model="dialog2"
    >
      <template v-slot:default="dialog">
        <v-card class="bg-white poppins">
          <v-card-title class="h5">Progress</v-card-title>
          <v-card-text class="h-50 h6" v-html="messagesFormated"> </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn color="red" text @click="toggleModal">Close</v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
  </v-dialog>
</template>

<script>
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import SkuDatatable from "@/own/components/fulfillment/orders/skuContainerCreate2/SkuDatatable";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { SET_B2B_CARTONS } from "@/core/services/store/orders.module";
import getPusherInstance from "@/core/services/pusher.service";

export default {
  name: "AddItem2",
  mixins: [validationMixin],
  components: {
    SkuDatatable,
  },

  // props: ["itemForAction", "refresher", "pageLoader"],

  props: {
    refresher: {
      required: true,
      type: Function,
    },
    pageLoader: {
      required: true,
      type: Function,
    },
  },

  validations() {
    return {
      order_details: {
        store: { required },
        warehouse: { required },
        client_reference: { required },
      },
    };
  },
  data: () => ({
    tabValidationState: [true, true, true, true, true, true],

    order_details: {
      note: null,
      store: null,
      order_type: "b2b",
      // return_type: null,
      client_reference: null,
      warehouse: null,
    },

    dialog: false,
    dialog2: false,
    messages: [],
    serverData: null,
    tab: 0,
    categories: [
      {
        name: "Order Details",
        valid: true,
        rules: (el) => {
          try {
            // console.log(el[`$v`][`order_details`]);
            return el[`$v`][`order_details`].$invalid;
          } catch {
            return true;
          }
        },
      },

      {
        name: "Items",
        valid: true,
        rules: (el) => {
          try {
            return !el.createB2BCartons.length > 0;
          } catch (error) {
            // console.log(error);
            return true;
          }
        },
      },
    ],
  }),
  methods: {
    initSocketConnection: function (eventName) {
      const pusher = getPusherInstance();
      const channel = pusher.subscribe(this.userSocketData.channel);
      channel.bind(`${eventName}`, (notification) => {
        this.messages.push(notification);
      });
    },
    nextStep(type) {
      this.updateValidationStateOfSteps();
      if (type == "next") {
        if (this.tab < 1) {
          this.tab += 1;
        }
      } else {
        if (this.tab > 0) {
          this.tab -= 1;
        }
      }
    },

    updateValidationStateOfSteps() {
      // console.log(`working`);
      this.$v.$touch();
      this.categories.forEach((cat) => {
        cat.valid = !cat.rules(this);
        // console.log(cat);
      });
    },
    resetValidationStateOfSteps() {
      this.categories.forEach((cat) => {
        cat.valid = true;
      });
    },
    handleFormValidation(fieldName, vueObj, dataName) {
      const errors = [];
      if (!vueObj.$v[`${dataName}`][fieldName].$dirty) return errors;
      if ("required" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].required &&
          errors.push("This field is required");
      }
      if ("maxValue" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].maxValue &&
          errors.push("This field must be greater than 0");
      }
      if ("url" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].url &&
          errors.push("This url is invalid");
      }
      if ("numeric" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].numeric &&
          errors.push("This must be a number");
      }
      if ("email" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].email &&
          errors.push("This email address is invalid");
      }
      return errors;
    },
    async loadDataFromServer() {
      this.pageLoader(true);
      let data = { id: this.itemForAction };
      await ApiService.post(`/fulfillment/orders/create`, data)
        .then((response) => {
          this.serverData = response.data;
          this.resetEditForm().finally(() => {
            this.pageLoader(false);
            this.updateValidationStateOfSteps();
          });
        })
        .catch(() => {
          this.dialog = false;
          this.pageLoader(false);
        });
    },

    submitEditForm() {
      this.updateValidationStateOfSteps();
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      if (this.createB2BCartons.length === 0) {
        Swal.fire({
          title: "Warning",
          html: `<div>At least one sku is required!</div>`,
          icon: "warning",
          showConfirmButton: false,
          timer: 2500,
        });
        return;
      }

      this.pageLoader(true);
      let data = JSON.parse(
        JSON.stringify({
          cartons: this.createB2BCartons.map((item) => item.carton.index),
          ...this.order_details,
        })
      );

      ApiService.post(`/fulfillment/orders/store`, data)
        .then(({ data }) => {
          this.dialog2 = true;
          this.initSocketConnection(data.event);
          Swal.fire({
            title: "Created",
            text: `Order has been created`,
            icon: "success",
            showConfirmButton: false,
            timer: 2500,
          });
          this.refresher();
        })
        .catch(() => {
          this.pageLoader(false);
        });
    },
    async resetEditForm() {
      this.$v.$reset();

      this.$store.commit(SET_B2B_CARTONS, []);

      this.order_details = {
        note: null,
        store: null,
        order_type: "b2b",
        // return_type: null,
        client_reference: null,
        warehouse: null,
      };

      this.tab = 0;
      await this.resetValidationStateOfSteps();
    },
    toggleModal() {
      if (this.dialog) {
        this.dialog2 = false;
        this.dialog = !this.dialog;
        this.serverData = null;
        this.messages = [];
        this.resetEditForm();
      } else {
        this.loadDataFromServer();
        this.dialog = !this.dialog;
      }
    },
    dateFormatter(date) {
      let d = date;
      return (
        [
          d.getFullYear(),
          d.getMonth() + 1 > 9 ? d.getMonth() + 1 : `0${d.getMonth() + 1}`,
          d.getDate() > 9 ? d.getDate() : `0${d.getDate()}`,
        ].join("-") +
        " " +
        [
          d.getHours() > 9 ? d.getHours() : `0${d.getHours()}`,
          d.getMinutes() > 9 ? d.getMinutes() : `0${d.getMinutes()}`,
          d.getSeconds() > 9 ? d.getSeconds() : `0${d.getSeconds()}`,
        ].join(":")
      );
    },
  },
  computed: {
    messagesFormated() {
      if (this.messages.length > 0) {
        return (
          "<v-list-item class='overflow-y-auto'>" +
          this.messages
            .map((mes) => {
              return `<v-list-item-content><div class=" ${
                mes.type === "success"
                  ? "text-success"
                  : mes.type === "end" || mes.type === "start"
                  ? "text-primary py-1"
                  : mes.type === "error"
                  ? "text-danger"
                  : ""
              } " ><span>${this.dateFormatter(
                new Date(mes.time * 1000)
              )}</span> - ${mes.message}</div></v-list-item-content>`;
            })
            .join("") +
          "</v-list-item>"
        );
      } else {
        return "";
      }
    },
    userSocketData: function () {
      /**
       * @type {{key:string,wsHost:string,wsPort:number,wssPort:number,channel:string,event:string}}
       * @example {"key":"BKFNMJNMGF","wsHost":"apitest.iqfulfillment.com","wsPort":6001,"wssPort":6001,"channel":"user.127","event":".notification.created"}
       */
      const socketData = this.$store.getters.getSocketData;
      return socketData;
    },
    createB2BCartons() {
      return this.$store.getters.getORDERSCreateB2BCartons;
    },
    backState() {
      return this.tab !== 0;
    },
    nextState() {
      return this.tab !== 1;
    },

    isStepperReady() {
      try {
        return this.$v ? true : false;
      } catch (error) {
        return false;
      }
    },
    userId: function () {
      return this.$store.getters.currentUser.data.is_client
        ? this.$store.getters.currentUser.data.id
        : this.order_details.customer_id;
    },

    isDataLoadedFromServer: function () {
      return !!this.serverData;
    },

    storeErrors: function () {
      return this.handleFormValidation("store", this, "order_details");
    },
    warehouseErrors: function () {
      return this.handleFormValidation("warehouse", this, "order_details");
    },

    client_referenceErrors: function () {
      return this.handleFormValidation(
        "client_reference",
        this,
        "order_details"
      );
    },
  },
};
</script>

<style scoped>
.modal-body {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: flex-end;
}
.modal-root {
  background-color: white !important;
  height: 100%;
}
.modal-action {
  justify-self: flex-end;
  height: 100%;
  justify-content: flex-end;
  align-items: flex-end;
}
.form-body {
  max-height: 64vh !important;
  height: 64vh !important;
  overflow-y: scroll;
}
.step-head {
}
.step-head-main {
  display: flex;
  flex-flow: row;
  overflow-x: scroll;
  overflow-y: hidden;
  width: 100%;
  height: 100%;
}
</style>
