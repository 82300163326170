<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="600px">
      <div class="bg-white poppins">
        <div class="modal-header align-center">
          <h3 class="mb-0 font-weight-bolder">SKU</h3>
          <div
            class="btn btn-sm btn-icon btn-active-light-info"
            @click="dialog = false"
          >
            <span class="svg-icon">
              <v-icon size="22">mdi-close</v-icon>
            </span>
          </div>
        </div>
        <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7">
          <!--begin::Body-->
          <form class="pt-4">
            <v-autocomplete
              v-model="formData.skuId"
              label="SKU"
              item-text="systemCode"
              item-value="skuId"
              clearable
              outlined
              :items="items"
              :loading="isLoading"
              :search-input.sync="search"
              hide-no-data
              hide-selected
              dense
              @change="updateSystemCode"
              :error-messages="skuIdErrors"
              @input="$v.formData.skuId.$touch()"
              @blur="$v.formData.skuId.$touch()"
            ></v-autocomplete>
            <v-text-field
              v-model="formData.quantity"
              label="Quantity"
              type="Number"
              @change="() => validateMinValue('quantity', 0, 'formData')"
              clearable
              outlined
              dense
              @input="$v.formData.quantity.$touch()"
              @blur="$v.formData.quantity.$touch()"
            ></v-text-field>
            <v-text-field
              v-model="formData.discounted_price"
              label="Discounted price"
              type="Number"
              @change="
                () => validateMinValue('discounted_price', 0, 'formData')
              "
              clearable
              outlined
              dense
            ></v-text-field>
          </form>
          <!--end::Body-->
          <div class="my-4 d-flex flex-grow-1 align-end justify-center">
            <button
              type="reset"
              class="btn btn-light mr-3 px-5 py-3 ls1"
              @click="resetFormData"
            >
              Reset
            </button>
            <button
              type="submit"
              class="btn btn-info px-5 py-3 ls1"
              @click="submitEditForm"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { getToken } from "@/core/services/jwt.service";
import fieldValueValidation from "@/own/mixins/fieldValueValidation.js";
export default {
  name: "EditSku",
  mixins: [validationMixin, fieldValueValidation],
  validations: {
    formData: {
      skuId: { required },
      quantity: { required },
    },
  },
  props: ["item", "actionFunction", "skuItem", "oldIndex", "clientId"],
  data: () => ({
    dialog: false,
    isLoading: false,
    search: null,
    count: null,
    entries: [],
    formData: {
      skuId: null,
      systemCode: null,
      alterSkuName: null,
      description: null,
      quantity: null,
      discounted_price: null,
    },
  }),
  methods: {
    updateSystemCode() {
      let data = false;
      this.items.forEach((item) => {
        if (item.skuId == this.formData.skuId) {
          data = item;
        }
      });
      for (const [key, value] of Object.entries(data)) {
        this.formData[`${key}`] = value;
      }
    },
    handleFormValidation(fieldName, vueObj) {
      const errors = [];
      if (!vueObj.$v.formData[fieldName].$dirty) return errors;
      if ("required" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      return errors;
    },
    submitEditForm() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        let copy = { ...this.formData };
        this.actionFunction(copy, this.oldIndex);
        this.toggleModal();
      }
    },
    resetFormData() {
      this.$v.$reset();
      this.entries = this.skuItem;
      if (this.item) {
        this.getData(this.item.systemCode);
        let copy = { ...this.item };
        Object.entries(this.formData).forEach((localData) => {
          Object.entries(this.item).forEach((serverData) => {
            if (localData[0] == serverData[0]) {
              this.formData[localData[0]] = copy[serverData[0]];
            }
          });
        });
      } else {
        this.formData = {
          skuId: null,
          systemCode: null,
          alterSkuName: null,
          description: null,
          quantity: null,
          discounted_price: null,
        };
      }
    },
    toggleModal() {
      this.resetFormData();
      this.dialog = !this.dialog;
    },
    async getData(value) {
      await fetch(`${process.env.VUE_APP_BASE_URL}/skus/search`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify({ q: value, client: this.clientId }),
      })
        .then((res) => res.json())
        .then((res) => {
          this.entries = res.skus;
        })
        .catch()
        .finally(() => (this.isLoading = false));
    },
  },
  computed: {
    items: function () {
      return this.entries;
    },
    skuIdErrors: function () {
      return this.handleFormValidation("skuId", this);
    },
    quantity: function () {
      return this.handleFormValidation("quantity", this);
    },
  },
  watch: {
    search(val) {
      this.isLoading = true;

      this.getData(val);
    },
  },
};
</script>
