<template>
  <v-dialog
    v-model="dialog"
    persistent
    scrollable
    max-width="1200px"
    v-if="isDataLoadedFromServer"
    style="background-color: white !important; height: 80vh !important"
  >
    <div class="bg-white poppins modal-root">
      <div class="modal-header align-center">
        <h3 class="mb-0 font-weight-bolder">
          Edit Order ({{ serverData.data[1].data[1].value }})
        </h3>
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
      </div>

      <div class="d-block pa-4">
        <!--begin::Body-->
        <v-stepper
          v-model="tab"
          non-linear
          v-if="isStepperReady"
          @change="updateValidationStateOfSteps"
        >
          <v-stepper-header class="step-head-main">
            <v-stepper-step
              v-for="(item, i) in categories"
              :key="i"
              class="step-head"
              editable
              complete
              complete-icon="mdi-check-circle"
              edit-icon="mdi-check"
              error-icon="mdi-alert-circle"
              :step="i"
              :rules="[
                () => {
                  return item.valid;
                },
              ]"
            >
              {{ item.name }}
              <small v-if="!item.valid">Invalid</small>
            </v-stepper-step>
          </v-stepper-header>
          <v-stepper-items>
            <v-stepper-content step="0" class="pt-0">
              <v-card color="basil" flat>
                <pop-up-time-line
                  class="ma-0 pa-0"
                  :item="getItemData('timeline')"
                ></pop-up-time-line>
                <pop-up-text-group
                  :item="getItemData('text')"
                ></pop-up-text-group>
              </v-card>
            </v-stepper-content>
            <v-stepper-content step="1" class="pt-4">
              <v-card color="basil" flat class="row">
                <div class="col-12 col-sm-6">
                  <v-select
                    v-model="order_details.warehouse"
                    label="Warehouse"
                    item-text="text"
                    item-value="index"
                    :items="serverData.warehouses"
                    outlined
                    dense
                    :clearable="!getIsReadOnly"
                    :readonly="getIsReadOnly"
                    :error-messages="warehouseErrors"
                    @input="$v.order_details.warehouse.$touch()"
                    @blur="$v.order_details.warehouse.$touch()"
                  ></v-select>
                </div>

                <div class="col-12 col-sm-6">
                  <v-select
                    v-model="order_details.store"
                    label="Store"
                    item-text="text"
                    item-value="index"
                    :items="serverData.stores"
                    :clearable="!getIsReadOnly"
                    :readonly="getIsReadOnly"
                    outlined
                    dense
                    :error-messages="storeErrors"
                    @input="$v.order_details.store.$touch()"
                    @blur="$v.order_details.store.$touch()"
                  ></v-select>
                </div>
                <!--                <div class="col-12 col-sm-6 col-md-4">-->
                <!--                  <v-text-field-->
                <!--                    v-model="order_details.client_reference"-->
                <!--                    label="Client reference"-->
                <!--                    clearable-->
                <!--                    outlined-->
                <!--                    dense-->
                <!--                    :error-messages="client_referenceErrors"-->
                <!--                    @input="$v.order_details.client_reference.$touch()"-->
                <!--                    @blur="$v.order_details.client_reference.$touch()"-->
                <!--                  ></v-text-field>-->
                <!--                </div>-->
                <div class="col-12">
                  <v-text-field
                    v-model="order_details.comment"
                    label="Comment"
                    :clearable="!getIsReadOnly"
                    :readonly="getIsReadOnly"
                    outlined
                    dense
                  ></v-text-field>
                </div>
              </v-card>
            </v-stepper-content>
            <v-stepper-content step="2" class="pt-2">
              <v-card color="basil" flat>
                <v-col cols="12" class="ma-0 pa-0">
                  <sku-datatable
                    :setSku="setSkus"
                    :skus="formData.skus"
                    :addSku="addSkus"
                    :skuItem="serverData.skus"
                    :clientId="serverData.order.client_id"
                    :warehouse="order_details.warehouse"
                    :store="order_details.store"
                    :isReadOnly="getIsReadOnly"
                  ></sku-datatable>
                </v-col>
              </v-card>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
        <!-- <perfect-scrollbar>
          <form id="editForm" class="form-body">
            <v-tabs-items v-model="tab" class="">
              <v-tab-item key="Status History" class="pt-2"> </v-tab-item>
              <v-tab-item key="Cosignee Details" class="pt-2"> </v-tab-item>
              <v-tab-item key="Order Details" class="pt-2"> </v-tab-item>
              <v-tab-item key="Items" class="pt-2"> </v-tab-item>
              <v-tab-item key="Call History" class="pt-2"> </v-tab-item>
              <v-tab-item key="Address" class="pt-2"> </v-tab-item>
              <v-tab-item key="Current Status" class="pt-2"> </v-tab-item>
            </v-tabs-items>
          </form>
        </perfect-scrollbar> -->
        <!--end::Body-->

        <div
          class="d-flex align-center justify-center justify-sm-space-between modal-action flex-wrap pt-4"
        >
          <v-btn
            width="120"
            @click="
              () => {
                const id = serverData.order.id;
                toggleModal();
                showPrevOrder(id);
              }
            "
            class="order-4 order-sm-1"
            >previous</v-btn
          >

          <!-- <button
            type="reset"
            class="btn btn-light-danger px-5 py-3 order-3 order-sm-2"
            @click="showCancelModal"
          >
            Cancel Order
          </button>

          <button
            type="submit"
            class="btn btn-warning px-5 py-3 order-2 order-sm-3"
            @click="showHoldOnModal"
          >
            On Hold
          </button> -->
          <button
            type="submit"
            class="btn btn-info px-5 py-3 order-1 order-sm-4"
            @click="submitEditForm"
            :disabled="isReadOnly"
          >
            Update
          </button>
          <v-btn
            width="120"
            @click="
              () => {
                const id = serverData.order.id;
                toggleModal();
                showNextOrder(id);
              }
            "
            class="order-5 order-sm-5"
            >next</v-btn
          >
        </div>
      </div>
    </div>
    <v-dialog v-model="dialog2" scrollable max-width="300px">
      <v-card>
        <v-card-title>Select Hold On Reason</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 300px">
          <v-radio-group v-model="holdOn_reason" column>
            <v-radio
              v-for="item in extraData.hold_reasons"
              :label="item.text"
              :value="item.index"
              :key="item.value"
            ></v-radio>
          </v-radio-group>

          <v-menu
            v-if="holdOn_reason == 1030"
            v-model="menu2"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="holdOn_date"
                label="Date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="holdOn_date"
              @input="menu2 = false"
            ></v-date-picker>
          </v-menu>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-end">
          <v-btn color="blue darken-1" text @click="dialog2 = false">
            Close
          </v-btn>
          <v-btn color="blue darken-1" text @click="actionOnHold">
            On Hold
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog3" scrollable max-width="300px">
      <v-card>
        <v-card-title>Cancel Reason</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 300px">
          <v-radio-group v-model="cancel_reason" column>
            <v-radio
              v-for="item in extraData.cancel_reasons"
              :label="item.text"
              :value="item.index"
              :key="item.value"
            ></v-radio>
          </v-radio-group>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-end">
          <v-btn color="blue darken-1" text @click="dialog3 = false">
            Close
          </v-btn>
          <v-btn color="blue darken-1" text @click="actionCancel">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import SkuDatatable from "@/own/components/fulfillment/orders/skuContainer2/SkuDatatable";

import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import PopUpTimeLine from "@/own/components/fulfillment/orders/popup/PopUpTimeLine";
import PopUpTextGroup from "@/own/components/fulfillment/orders/popup/PopUpTextGroup";

export default {
  name: "EditItem2",
  mixins: [validationMixin],
  components: {
    PopUpTextGroup,
    PopUpTimeLine,
    SkuDatatable,
  },
  props: {
    itemForAction: {
      required: false,
      type: Number,
    },
    refresher: {
      required: true,
      type: Function,
    },
    pageLoader: {
      required: true,
      type: Function,
    },
    extraData: {
      required: true,
      type: Object,
    },
    showNextOrder: {
      required: true,
      type: Function,
    },
    showPrevOrder: {
      required: true,
      type: Function,
    },
  },
  // ["XDOCK" => 9, "XSDOCK" => 17, "BOX" => 1, "RT" => 7, "RVP" => 4]
  // $UAECountryId = 229

  validations() {
    return {
      order_details: {
        warehouse: { required },
        // client_reference: { required },
        store: { required },
      },
    };
  },
  data: () => ({
    isReadOnly: false,
    formData: {
      id: null,
      order_type: "b2b",
      skus: [],
    },

    order_details: {
      warehouse: null,
      store: null,
      comment: null,
      // client_reference: null,
    },

    dialog2: false,
    dialog3: false,
    dialog: false,
    menu2: false,
    cancel_reason: null,
    holdOn_reason: null,
    holdOn_date: null,
    serverData: null,
    tab: 0,
    categories: [
      {
        name: "Status History",
        valid: true,
        rules: () => {
          return false;
        },
      },
      {
        name: "Order Details",
        valid: true,
        rules: (el) => {
          try {
            return el[`$v`][`order_details`].$invalid;
          } catch {
            return true;
          }
        },
      },

      {
        name: "Items",
        valid: true,
        rules: (el) => {
          try {
            return !el.formData.skus.length > 0;
          } catch {
            return true;
          }
        },
      },
    ],
    // ckeditorType: ClassicEditor,
  }),
  methods: {
    updateValue(type) {
      let timer;
      const waitTime = 1250;
      clearTimeout(timer);
      timer = setTimeout(() => {
        this.onBlurValueCheck(type);
      }, waitTime);
    },

    updateValidationStateOfSteps() {
      this.$v.$touch();
      this.categories.forEach((cat) => {
        cat.valid = !cat.rules(this);
      });
    },
    resetValidationStateOfSteps() {
      this.categories.forEach((cat) => {
        cat.valid = true;
      });
    },
    getItemData(type) {
      return this.infoData.filter((data) => data.type === type)[0];
    },
    actionUpdateAndAudit() {},
    showCancelModal() {
      this.dialog3 = !this.dialog3;
    },
    actionCancel() {
      if (this.cancel_reason) {
        this.pageLoader(true);
        this.dialog3 = false;
        const data = {
          order_ids: [this.itemForAction],
          reason: this.cancel_reason,
        };
        ApiService.post(`/order_management/pending_audit_orders/cancel`, data)
          .then(() => {
            Swal.fire({
              title: "Updated",
              text: `Cancel reason registered!`,
              icon: "success",
              showConfirmButton: false,
              timer: 2500,
            });
            this.toggleModal();
            this.pageLoader(false);
            this.refresher();
          })
          .catch(() => {
            this.pageLoader(false);
          });
      }
    },
    showHoldOnModal() {
      this.dialog2 = !this.dialog2;
    },
    actionOnHold() {
      if (this.holdOn_reason) {
        this.pageLoader(true);
        this.dialog2 = false;
        const data = {
          order_ids: [this.itemForAction],
          reason: this.holdOn_reason,
          scheduled_date: this.holdOn_date,
        };
        ApiService.post(`/order_management/pending_audit_orders/hold`, data)
          .then(() => {
            Swal.fire({
              title: "Updated",
              text: `Hold On reason registered!`,
              icon: "success",
              showConfirmButton: false,
              timer: 2500,
            });
            this.toggleModal();
            this.pageLoader(false);
            this.refresher();
          })
          .catch(() => {
            this.pageLoader(false);
          });
      }
    },
    setSkus(item) {
      this.formData.skus = [...item];
    },
    addSkus(item) {
      this.formData.skus = [...this.formData.skus, ...item];
    },
    handleFormValidation(fieldName, vueObj, dataName = "formData") {
      const errors = [];
      if (!vueObj.$v[`${dataName}`][fieldName].$dirty) return errors;
      if ("required" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].required &&
          errors.push("This field is required");
      }
      if ("maxValue" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].maxValue &&
          errors.push("This field must be greater than 0");
      }
      if ("url" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].url &&
          errors.push("This url is invalid");
      }
      if ("numeric" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].numeric &&
          errors.push("This must be a number");
      }
      if ("email" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].email &&
          errors.push("This email address is invalid");
      }
      return errors;
    },
    async loadDataFromServer() {
      this.pageLoader(true);
      let data = { id: this.itemForAction };
      await ApiService.post(`/fulfillment/orders/edit`, data)
        .then((response) => {
          this.serverData = response.data;
          this.resetEditForm();
          this.pageLoader(false);
          this.updateValidationStateOfSteps();
        })
        .catch(() => {
          this.dialog = false;
          this.pageLoader(false);
        });
    },

    convertToFormData() {
      return this.formData;
    },

    submitEditForm() {
      this.updateValidationStateOfSteps();
      if (this.$v.$invalid) {
        this.$v.$touch();

        return;
      }
      this.$v.$touch();
      if (this.formData.skus.length === 0) {
        Swal.fire({
          title: "Warning",
          html: `<div>At least one item should be added</div>`,
          icon: "warning",
          showConfirmButton: false,
          timer: 2500,
        });
        return;
      }

      this.pageLoader(true);
      let data = {
        ...this.formData,

        ...this.order_details,
      };

      ApiService.post(`/fulfillment/orders/update`, data)
        .then(() => {
          Swal.fire({
            title: "Updated",
            text: `Order has been updated`,
            icon: "success",
            showConfirmButton: false,
            timer: 2500,
          });
          let id = this.serverData.order.id;
          this.showNextOrder(id);
        })
        .catch(() => {
          this.pageLoader(false);
        });
    },
    async resetEditForm() {
      this.$v.$reset();
      const dataNames = ["formData", "order_details"];
      if (this.serverData) {
        dataNames.forEach(async (dataName) => {
          let copy = { ...this.serverData.order };
          await Object.entries(this[`${dataName}`]).forEach((localD) => {
            Object.entries(this.serverData.order).forEach((serverD) => {
              if (localD[0] === serverD[0] && localD[0] !== "image") {
                this[`${dataName}`][localD[0]] = copy[serverD[0]];
              }
            });
          });
        });
      } else {
        this.formData = {
          id: null,
          skus: [],
          order_type: "b2b",
        };

        this.order_details = {
          warehouse: null,
          store: null,
          comment: null,
          client_reference: null,
        };
      }

      this.tab = 0;
      this.resetValidationStateOfSteps();
    },
    toggleModal(isReadOnly = false) {
      if (this.dialog) {
        this.dialog = !this.dialog;
        this.serverData = null;
        this.resetEditForm();
      } else {
        this.isReadOnly = isReadOnly;
        this.loadDataFromServer();
        this.dialog = !this.dialog;
      }
    },
  },
  computed: {
    getIsReadOnly() {
      return this.isReadOnly;
    },
    isStepperReady() {
      try {
        return this.$v ? true : false;
      } catch (error) {
        return false;
      }
    },

    isDataLoadedFromServer: function () {
      return !!this.serverData;
    },
    infoData() {
      return this.serverData.data;
    },

    warehouseErrors: function () {
      return this.handleFormValidation("warehouse", this, "order_details");
    },
    storeErrors: function () {
      return this.handleFormValidation("store", this, "order_details");
    },
    // client_referenceErrors: function () {
    //   return this.handleFormValidation(
    //     "client_reference",
    //     this,
    //     "order_details"
    //   );
    // },
  },
};
</script>

<style scoped>
.modal-body {
  height: 100%;
  align-content: flex-end;
}
.modal-root {
  background-color: white !important;
  height: 100%;
}
.modal-action {
  justify-self: flex-end;
  height: 100%;
}
.form-body {
  max-height: 80vh !important;
  height: 80vh !important;
  overflow-y: scroll;
}
.step-head {
}
.step-head-main {
  display: flex;
  flex-flow: row;
  overflow-x: scroll;
  overflow-y: hidden;
  width: 100%;
  height: 100%;
}
</style>
