import { render, staticRenderFns } from "./AddItem2.vue?vue&type=template&id=a6bbe470&scoped=true"
import script from "./AddItem2.vue?vue&type=script&lang=js"
export * from "./AddItem2.vue?vue&type=script&lang=js"
import style0 from "./AddItem2.vue?vue&type=style&index=0&id=a6bbe470&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a6bbe470",
  null
  
)

export default component.exports